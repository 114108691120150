import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React from 'react'
import { Link } from 'gatsby'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'
import OfficialFunctionItems from '../molecules/officialFunctionItems'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.section`
  width: 100%;

  .p-section-inner {
    max-width: 1080px;
    padding: 80px 0 0;
    margin: 0 auto;
    text-align: center;

    .p-section-function {
      padding: 30px 10px 20px;
      background: #ffffff;
      border-radius: 10px;
      width: 100%;
      margin: 0 auto 60px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .function-items {
        .description {
          width: 80%;
          margin: 0 auto;
        }
      }
    }

    button {
      display: flex;
      margin: 0 auto;
    }
  }

  ${BreakPoints.xxLarge} {
    .p-section-inner {
      .p-section-function {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .function-items {
          float: unset;

          .description {
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 60px 0 0;

      .p-section-function {
        .function-items {
          width: calc(100vw - 80px);
          height: auto;

          .description {
          }
        }
      }
    }
  }
`
const StyledLink = styled(Link)`
  width: 120px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 8px;
  color: #00b5a6;
  line-height: 32px;
  background: #ffffff;
  border: solid 1px #3ec7b3;
  text-decoration: none !important;
  position: absolute;
  left: calc((100% - 120px) / 2);
  bottom: -10px;

  &:hover {
    text-decoration: none !important;
  }

  ${BreakPoints.medium} {
    bottom: 0;
    left: unset;
    right: 90px;
  }
`

/**
 * サービスサイト機能-従業員向け機能
 * @constructor
 */
const OfficialLowFunction01 = () => {
  const routeParam = useRouteParam('/plan-list/')
  return (
    <Wrapper>
      <div className="p-section-inner">
        <OfficialCommonH3Set label="従業員向け機能" className="fade-in-auto" />
        <div className="p-section-function fade-in-auto">
          <OfficialFunctionItems
            label="打刻"
            description="専用機器不要で、PC・スマホから打刻可能"
          >
            <img
              src="/images/official/function/imgFunction01.png"
              alt="キンクラの打刻"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="シフト表"
            description="シフト作成、シフトマスタを個人ごとに設定可能"
          >
            <img
              src="/images/official/function/imgFunction03.png"
              alt="キンクラのシフト管理"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="勤務表"
            description="自社だけでなく、常駐先の勤務表も自動生成"
          >
            <img
              src="/images/official/function/imgFunction10.png"
              alt="キンクラの勤務表管理"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="各種申請"
            description="勤怠申請、MTG申請、定期代情報変更等"
            className="is-show"
          >
            <img
              src="/images/official/top/imgFunction02.png"
              alt="キンクラの各種申請"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="履歴確認"
            description="各種申請の履歴や自身の有給残日数など確認可能"
          >
            <img
              src="/images/official/function/imgFunction09.png"
              alt="キンクラの履歴確認"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="システム通知"
            description="自分に対するシステムアラートの通知"
          >
            <img
              src="/images/official/function/imgFunction41.png"
              alt="キンクラのシステム通知"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="経費精算"
            description="電子帳簿保存法対応したシンプルで簡単な経費清算"
          >
            <img
              src="/images/official/function/imgFunction47.png"
              alt="キンクラのSES案件管理"
            />
            <StyledLink to="/function/request-expense/">詳細を見る</StyledLink>
          </OfficialFunctionItems>
        </div>
        <OfficialCommonBtn
          size="mega"
          className="anim-fade-up"
          onClick={() => {
            navigate(routeParam)
          }}
        >
          プランごとの機能一覧を見る
        </OfficialCommonBtn>
      </div>
    </Wrapper>
  )
}

export default OfficialLowFunction01

import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'
import OfficialFunctionItems from '../molecules/officialFunctionItems'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.section`
  width: 100%;

  .p-section-inner {
    max-width: 1080px;
    padding: 80px 0;
    margin: 0 auto;
    text-align: center;

    .p-section-function {
      padding: 30px 10px 0;
      background: #ffffff;
      border-radius: 10px;
      width: 100%;
      margin: 0 auto 60px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .function-items {
        .description {
          width: 80%;
          margin: 0 auto;
        }
      }
    }

    button {
      display: flex;
      margin: 0 auto;
    }
  }

  ${BreakPoints.xxLarge} {
    .p-section-inner {
      .p-section-function {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .function-items {
          float: unset;

          .description {
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 60px 0;

      .p-section-function {
        .function-items {
          width: calc(100vw - 80px);
          height: auto;

          .description {
          }
        }
      }
    }
  }
`

/**
 * サービスサイト機能-システム設定機能
 * @constructor
 */
const OfficialLowFunction04 = () => {
  const routeParam = useRouteParam('/plan-list')
  return (
    <Wrapper>
      <div className="p-section-inner">
        <OfficialCommonH3Set
          label="システム設定機能"
          className="fade-in-auto"
        />
        <div className="p-section-function fade-in-auto">
          <OfficialFunctionItems
            label="承認ルール設定"
            description="各種申請のグループ単位での詳細な承認ルール設定"
          >
            <img
              src="/images/official/function/imgFunction19.png"
              alt="キンクラの承認ルール設定"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="36協定設定"
            description="各企業で定めている労働時間を設定可能"
          >
            <img
              src="/images/official/function/imgFunction07.png"
              alt="キンクラの36協定設定"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="時間まるめ設定"
            description="出退勤や休憩などの打刻や勤怠申請を分単位で設定可能"
          >
            <img
              src="/images/official/function/imgFunction27.png"
              alt="キンクラの時間まるめ設定"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="有給休暇設定"
            description="有給休暇の有休の付与日と付与日数等設定"
          >
            <img
              src="/images/official/function/imgFunction29.png"
              alt="キンクラの有給休暇設定"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="特別休暇設定"
            description="企業独自の休暇を設定可能"
          >
            <img
              src="/images/official/function/imgFunction30.png"
              alt="キンクラの特別休暇設定"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="勤務形態管理"
            description="様々な勤務形態に対応"
          >
            <img
              src="/images/official/function/imgFunction31.png"
              alt="キンクラの勤務形態管理"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="勤務地管理"
            description="出勤する場所を管理"
          >
            <img
              src="/images/official/function/imgFunction23.png"
              alt="キンクラの勤務地管理"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="アラート管理"
            description="細かい管理アラートを設定可能"
          >
            <img
              src="/images/official/function/imgFunction35.png"
              alt="キンクラの勤務地管理"
            />
          </OfficialFunctionItems>
        </div>
        <OfficialCommonBtn
          size="mega"
          className="anim-fade-up"
          onClick={() => {
            navigate(routeParam)
          }}
        >
          プランごとの機能一覧を見る
        </OfficialCommonBtn>
      </div>
    </Wrapper>
  )
}

export default OfficialLowFunction04

import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React from 'react'
import { Link } from 'gatsby'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'
import OfficialFunctionItems from '../molecules/officialFunctionItems'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.section`
  width: 100%;

  .p-section-inner {
    max-width: 1080px;
    padding: 80px 0 0;
    margin: 0 auto;
    text-align: center;

    .p-section-function {
      padding: 30px 10px 20px;
      background: #ffffff;
      border-radius: 10px;
      width: 100%;
      margin: 0 auto 60px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .function-items {
        .description {
          width: 80%;
          margin: 0 auto;
        }
      }
    }

    button {
      display: flex;
      margin: 0 auto;
    }
  }

  ${BreakPoints.xxLarge} {
    .p-section-inner {
      .p-section-function {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .function-items {
          float: unset;

          .description {
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 60px 0 0;

      .p-section-function {
        .function-items {
          width: calc(100vw - 80px);
          height: auto;

          .description {
          }
        }
      }
    }
  }
`
const StyledLink = styled(Link)`
  width: 120px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 8px;
  color: #00b5a6;
  line-height: 32px;
  background: #ffffff;
  border: solid 1px #3ec7b3;
  text-decoration: none !important;
  position: absolute;
  left: calc((100% - 120px) / 2);
  bottom: -10px;

  &:hover {
    text-decoration: none !important;
  }

  ${BreakPoints.medium} {
    bottom: 0;
    left: unset;
    right: 90px;
  }
`

/**
 * サービスサイト機能-管理職向け機能
 * @constructor
 */
const OfficialLowFunction02 = () => {
  const routeParam = useRouteParam('/plan-list/')
  return (
    <Wrapper>
      <div className="p-section-inner">
        <OfficialCommonH3Set label="管理職向け機能" className="fade-in-auto" />
        <div className="p-section-function fade-in-auto">
          <OfficialFunctionItems
            label="申請承認"
            description="各種申請の承認、差し戻し、一括操作"
          >
            <img
              src="/images/official/function/imgFunction11.png"
              alt="キンクラの申請承認"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="残業管理"
            description="月間残業と年間残業の管理や、警告社員一覧"
          >
            <img
              src="/images/official/function/imgFunction38.png"
              alt="キンクラの残業管理"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="有給管理"
            description="従業員の有給付与日や有給付与日数を一覧で表示"
          >
            <img
              src="/images/official/function/imgFunction39.png"
              alt="キンクラの有給管理"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="出勤管理"
            description="出退勤時刻や休憩取得時間を一覧で表示"
          >
            <img
              src="/images/official/function/imgFunction40.png"
              alt="キンクラの出勤管理"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="勤務実態集計"
            description="出社や在宅状況の表示など勤務実態を一覧で表示"
          >
            <img
              src="/images/official/function/imgFunction37.png"
              alt="キンクラの勤務実態集計"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="管理アラート"
            description="設定された閾値を元に、キンクラからアラートを通知"
          >
            <img
              src="/images/official/function/imgFunction05.png"
              alt="キンクラの管理アラート"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="SES案件管理"
            description="契約期間や単価情報などSES案件に関するデータを管理"
          >
            <img
              src="/images/official/function/imgFunction06.png"
              alt="キンクラのSES案件管理"
            />
            <StyledLink to="/function/ses-management/">詳細を見る</StyledLink>
          </OfficialFunctionItems>
        </div>
        <OfficialCommonBtn
          size="mega"
          className="anim-fade-up"
          onClick={() => {
            navigate(routeParam)
          }}
        >
          プランごとの機能一覧を見る
        </OfficialCommonBtn>
      </div>
    </Wrapper>
  )
}

export default OfficialLowFunction02

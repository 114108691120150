import styled from '@emotion/styled'
import { graphql, PageProps } from 'gatsby'
import React from 'react'
import SEO from '../components/atoms/seo'
import OfficialLayout from '../components/organisms/officialLayout'
import OfficialLowFunction01 from '../components/organisms/officialLowFunction01'
import OfficialLowFunction02 from '../components/organisms/officialLowFunction02'
import OfficialLowFunction03 from '../components/organisms/officialLowFunction03'
import OfficialLowFunction04 from '../components/organisms/officialLowFunction04'
import OfficialLowHeader from '../components/organisms/officialLowHeader'
import OfficialGalleryBtnSet from '../components/molecules/officialGalleryBtnSet'
import OfficialNewFunctionItem from '../components/organisms/officialNewFunctionItem'

const Wrapper = styled.main`
  .no-arrow {
    margin-bottom: 0 !important;

    &:after {
      display: none !important;
    }
  }
`

type IProps = PageProps<GatsbyTypes.IndexPageQuery>

/**
 * 機能ページ[サービスサイト機能]
 * @constructor
 */
const FunctionPage = ({ location }: IProps) => (
  <OfficialLayout location={location} className="p-page-function">
    <SEO title="機能一覧" />
    <Wrapper>
      <OfficialLowHeader
        label="機能一覧"
        description="勤怠状況のリアルタイム把握と業務効率化をサポートする充実の機能"
      />
      <OfficialLowFunction01 />
      <OfficialLowFunction02 />
      <OfficialLowFunction03 />
      <OfficialLowFunction04 />
      <OfficialNewFunctionItem className="no-arrow" />
      <OfficialGalleryBtnSet />
    </Wrapper>
  </OfficialLayout>
)

export default FunctionPage

export const pageQuery = graphql`
  query FunctionPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`

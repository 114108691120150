import styled from '@emotion/styled'
import { navigate } from 'gatsby-link'
import React from 'react'
import { Link } from 'gatsby'
import BreakPoints from '../../styles/breakPoints'
import OfficialCommonBtn from '../atoms/officialCommonBtn'
import OfficialCommonH3Set from '../molecules/officialCommonH3Set'
import OfficialFunctionItems from '../molecules/officialFunctionItems'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.section`
  width: 100%;

  .p-section-inner {
    max-width: 1080px;
    padding: 80px 0 0;
    margin: 0 auto;
    text-align: center;

    .p-section-function {
      padding: 30px 10px 0;
      background: #ffffff;
      border-radius: 10px;
      width: 100%;
      margin: 0 auto 60px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .function-items {
        .description {
          width: 80%;
          margin: 0 auto;
        }
      }
    }

    button {
      display: flex;
      margin: 0 auto;
    }
  }

  ${BreakPoints.xxLarge} {
    .p-section-inner {
      .p-section-function {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .function-items {
          float: unset;

          .description {
          }
        }
      }
    }
  }

  ${BreakPoints.medium} {
    .p-section-inner {
      padding: 60px 0 0;

      .p-section-function {
        .function-items {
          width: calc(100vw - 80px);
          height: auto;

          .description {
          }
        }
      }
    }
  }
`

const StyledLink = styled(Link)`
  width: 120px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border-radius: 8px;
  color: #00b5a6;
  line-height: 32px;
  background: #ffffff;
  border: solid 1px #3ec7b3;
  text-decoration: none !important;
  position: absolute;
  left: calc((100% - 120px) / 2);
  bottom: -10px;

  &:hover {
    text-decoration: none !important;
  }

  ${BreakPoints.medium} {
    bottom: 15px;
    left: unset;
    right: 90px;
  }
`

/**
 * サービスサイト機能-人事労務向け機能
 * @constructor
 */
const OfficialLowFunction03 = () => {
  const routeParam = useRouteParam('/plan-list/')
  return (
    <Wrapper>
      <div className="p-section-inner">
        <OfficialCommonH3Set
          label="人事労務向け機能"
          className="fade-in-auto"
        />
        <div className="p-section-function fade-in-auto">
          <OfficialFunctionItems
            label="代理申請"
            description="社員に代わり、打刻や各種申請を代行することが可能"
          >
            <img
              src="/images/official/function/imgFunction46.png"
              alt="キンクラの代理申請"
            />
            <StyledLink to="/function/agent-mode/">詳細を見る</StyledLink>
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="お知らせ管理"
            description="お知らせ記事の登録、編集"
          >
            <img
              src="/images/official/function/imgFunction13.png"
              alt="キンクラのお知らせ管理"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="休暇付与"
            description="有給の自動付与や任意に休暇付与が可能"
          >
            <img
              src="/images/official/function/imgFunction21.png"
              alt="キンクラの休暇付与"
            />
          </OfficialFunctionItems>
          <OfficialFunctionItems
            label="給与ソフト連携"
            description="給与ソフトに合わせてエクスポートデータを生成"
          >
            <img
              src="/images/official/function/imgFunction04.png"
              alt="キンクラの給与ソフト連携"
            />
          </OfficialFunctionItems>
        </div>
        <OfficialCommonBtn
          size="mega"
          className="anim-fade-up"
          onClick={() => {
            navigate(routeParam)
          }}
        >
          プランごとの機能一覧を見る
        </OfficialCommonBtn>
      </div>
    </Wrapper>
  )
}

export default OfficialLowFunction03
